import { Box, Button, Container, Grid, ImageList, ImageListItem, Paper } from "@mui/material";
import React from 'react';
import LiteBox from "../components/LiteBox";

const Gallery2 = () => {

  const [showLiteBox, setShowLiteBox] = React.useState(false);
  const [liteboxImage, setliteboxImage] = React.useState('');

  const setImage = (item) => {
    console.log(item);
    setliteboxImage(item.img);
    setShowLiteBox(true);
  }

  const itemData = [
    { img: "img/gallery_2/001.jpg", title: "murburus", rows: 5, cols: 1 },
    { img: "img/gallery_2/002.jpg", title: "murburus", rows: 5, cols: 1 },

    { img: "img/gallery_2/005.jpg", title: "murburus", rows: 5, cols: 1 },
    { img: "img/gallery_2/006.jpg", title: "murburus", rows: 5, cols: 1 },
    { img: "img/gallery_2/007.jpg", title: "murburus", rows: 5, cols: 1 },
    { img: "img/gallery_2/008.jpg", title: "murburus", rows: 5, cols: 1 },
    { img: "img/gallery_2/009.jpg", title: "murburus", rows: 5, cols: 1 },
    { img: "img/gallery_2/010.jpg", title: "murburus", rows: 5, cols: 1 },
    { img: "img/gallery_2/011.jpg", title: "murburus", rows: 5, cols: 1 },
    { img: "img/gallery_2/012.jpg", title: "murburus", rows: 5, cols: 1 },
    { img: "img/gallery_2/013.jpg", title: "murburus", rows: 5, cols: 1 },
    { img: "img/gallery_2/014.jpg", title: "murburus", rows: 5, cols: 1 },
    { img: "img/gallery_2/015.jpg", title: "murburus", rows: 5, cols: 1 },
    { img: "img/gallery_2/016.jpg", title: "murburus", rows: 5, cols: 1 },
    { img: "img/gallery_2/017.jpg", title: "murburus", rows: 5, cols: 1 },
    { img: "img/gallery_2/018.jpg", title: "murburus", rows: 5, cols: 1 },
    { img: "img/gallery_2/019.jpg", title: "murburus", rows: 5, cols: 1 },
    { img: "img/gallery_2/020.jpg", title: "murburus", rows: 5, cols: 1 },
    { img: "img/gallery_2/021.jpg", title: "murburus", rows: 5, cols: 1 },
    { img: "img/gallery_2/022.jpg", title: "murburus", rows: 5, cols: 1 },
    { img: "img/gallery_2/023.jpg", title: "murburus", rows: 5, cols: 1 },
    { img: "img/gallery_2/024.jpg", title: "murburus", rows: 5, cols: 1 },
    { img: "img/gallery_2/025.jpg", title: "murburus", rows: 5, cols: 1 },
    { img: "img/gallery_2/026.jpg", title: "murburus", rows: 5, cols: 1 },
    { img: "img/gallery_2/027.jpg", title: "murburus", rows: 5, cols: 1 },
    { img: "img/gallery_2/028.jpg", title: "murburus", rows: 5, cols: 1 },
    { img: "img/gallery_2/029.jpg", title: "murburus", rows: 5, cols: 1 },
    { img: "img/gallery_2/030.jpg", title: "murburus", rows: 5, cols: 1 },
    { img: "img/gallery_2/031.jpg", title: "murburus", rows: 5, cols: 1 },
    { img: "img/gallery_2/032.jpg", title: "murburus", rows: 5, cols: 1 },
    { img: "img/gallery_2/033.jpg", title: "murburus", rows: 5, cols: 1 },
    { img: "img/gallery_2/034.jpg", title: "murburus", rows: 5, cols: 1 },
    { img: "img/gallery_2/035.jpg", title: "murburus", rows: 5, cols: 1 },
    { img: "img/gallery_2/036.jpg", title: "murburus", rows: 5, cols: 1 },
    { img: "img/gallery_2/037.jpg", title: "murburus", rows: 5, cols: 1 },
    { img: "img/gallery_2/038.jpg", title: "murburus", rows: 5, cols: 1 },
    { img: "img/gallery_2/039.jpg", title: "murburus", rows: 5, cols: 1 },
    { img: "img/gallery_2/040.jpg", title: "murburus", rows: 5, cols: 1 },
    { img: "img/gallery_2/041.jpg", title: "murburus", rows: 5, cols: 1 },
    { img: "img/gallery_2/042.jpg", title: "murburus", rows: 5, cols: 1 },
    { img: "img/gallery_2/043.jpg", title: "murburus", rows: 5, cols: 1 },
    { img: "img/gallery_2/044.jpg", title: "murburus", rows: 5, cols: 1 },
    { img: "img/gallery_2/045.jpg", title: "murburus", rows: 5, cols: 1 },
    { img: "img/gallery_2/046.jpg", title: "murburus", rows: 5, cols: 1 },
    { img: "img/gallery_2/047.jpg", title: "murburus", rows: 5, cols: 1 },
    { img: "img/gallery_2/048.jpg", title: "murburus", rows: 5, cols: 1 },
    { img: "img/gallery_2/049.jpg", title: "murburus", rows: 5, cols: 1 },
    { img: "img/gallery_2/050.jpg", title: "murburus", rows: 5, cols: 1 },
    { img: "img/gallery_2/051.jpg", title: "murburus", rows: 5, cols: 1 },
    { img: "img/gallery_2/052.jpg", title: "murburus", rows: 5, cols: 1 },
    { img: "img/gallery_2/053.jpg", title: "murburus", rows: 5, cols: 1 },
    { img: "img/gallery_2/054.jpg", title: "murburus", rows: 5, cols: 1 },
    { img: "img/gallery_2/055.jpg", title: "murburus", rows: 5, cols: 1 },
    { img: "img/gallery_2/056.jpg", title: "murburus", rows: 5, cols: 1 },
    { img: "img/gallery_2/057.jpg", title: "murburus", rows: 5, cols: 1 },
    { img: "img/gallery_2/058.jpg", title: "murburus", rows: 5, cols: 1 },
    { img: "img/gallery_2/059.jpg", title: "murburus", rows: 5, cols: 1 },
    { img: "img/gallery_2/060.jpg", title: "murburus", rows: 5, cols: 1 },
    { img: "img/gallery_2/061.jpg", title: "murburus", rows: 5, cols: 1 },
    { img: "img/gallery_2/062.jpg", title: "murburus", rows: 5, cols: 1 },
    { img: "img/gallery_2/063.jpg", title: "murburus", rows: 5, cols: 1 },
    { img: "img/gallery_2/064.jpg", title: "murburus", rows: 5, cols: 1 },
    { img: "img/gallery_2/065.jpg", title: "murburus", rows: 5, cols: 1 },
    { img: "img/gallery_2/066.jpg", title: "murburus", rows: 5, cols: 1 },
    { img: "img/gallery_2/067.jpg", title: "murburus", rows: 5, cols: 1 },
    { img: "img/gallery_2/068.jpg", title: "murburus", rows: 5, cols: 1 },
    { img: "img/gallery_2/069.jpg", title: "murburus", rows: 5, cols: 1 },
    { img: "img/gallery_2/070.jpg", title: "murburus", rows: 5, cols: 1 },
    { img: "img/gallery_2/071.jpg", title: "murburus", rows: 5, cols: 1 },
    
  ]

  return (
    <Container>
      <div className='desktop-only'>
        <ImageList
          sx={{ width: '100%', height: 'auto', maxWidth: '1400px' }}
          variant="quilted"
          cols={4}
          rowHeight={121}
        >
          {itemData.map((item) => (
            <ImageListItem key={item.img} cols={item.cols || 1} rows={item.rows || 1} onClick={() => setImage(item)}>
              <img
                // {...srcset(item.img, 121, item.rows, item.cols)}
                // src={`${item.img}?w=121&h=121&fit=crop&auto=format`}
                src={`${item.img}`}
                alt={item.title}
                loading="lazy"
              />
            </ImageListItem>
          ))}
        </ImageList>
      </div>

      <div className='mobile-only'>
        <ImageList
          sx={{ width: '100%', height: 'auto', maxWidth: '1400px' }}
          variant="masonry"
          cols={1}
          gap={8}
        >
          {itemData.map((item) => (
            <ImageListItem key={item.img} cols={1} rows={1}>
              <img
                // {...srcset(item.img, 121, item.rows, item.cols)}
                // src={`${item.img}?w=121&h=121&fit=crop&auto=format`}
                src={`${item.img}`}
                alt={item.title}
                loading="lazy"
              />
            </ImageListItem>
          ))}
        </ImageList>
      </div>

      <LiteBox image={liteboxImage} show={showLiteBox} setShow={setShowLiteBox} />
    </Container>
  );
};

export default Gallery2;