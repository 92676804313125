import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Header from './Header';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { AppBar, Box, Button, Container } from '@mui/material';
import RsvpModal from './components/RsvpModal';
import Info from './pages/Info';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Fairfield from './pages/Fairfield';
import React from 'react';
import Photos from './pages/Photos';
import Gallery1 from './pages/Gallery1';
import Gallery2 from './pages/Gallery2';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});


function App() {
  

  const [open, setOpen] = React.useState(false);


  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />

      <div className="App">
        <div id='top-wrap'>
          <Header />
          <AppBar position='static' className='menu-bar'>
            <Container>
              <Box>
                <Button variant='outline' href='/'>HOME</Button>
                {/* <RsvpModal linkType={'button'} open={open} setOpen={setOpen} />
                <Button variant='outline' href='/info'>WEDDING INFO</Button>
                <Button variant='outline' href='/fairfield'>FAIRFIELD ATTRACTIONS</Button> */}
                <Button variant='outline' href='/photos'>PHOTOS</Button>
              </Box>
            </Container>
          </AppBar>
          {/* use react router to create 3 pages, home, rsvp, and info */}
          <main id='main-content'>
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<Home setOpenRsvp={setOpen} />} />
                <Route path="/info" element={<Info setOpenRsvp={setOpen} />} />
                <Route path="/fairfield" element={<Fairfield />} />
                <Route path="/photos" element={<Photos />} />
                <Route path="/gallery1" element={<Gallery1 />} />
                <Route path="/gallery2" element={<Gallery2 />} />
                
              </Routes>
            </BrowserRouter>

          </main>

        </div>

        <footer id='main-footer'>
        </footer>
      </div>
    </ThemeProvider>

  );
}

export default App;