import { Container, List, ListItem, Paper } from '@mui/material';
import React from 'react';
import Accommodations from './Accommodations';

const Fairfield = () => {
  return (
    <Container>
      <Paper sx={{ p: 2, mt: 2, mb: 3 }}>
        <h1>Welcome to Fairfield, Iowa!</h1>
        <h2>Things to See and Do</h2>
        <List>
          <ListItem>
            <p><b>Bring your bike!</b><br /> The loop trail around town is excellent. <a href='https://jeffersoncountyconservation.com/wp-content/uploads/JCTC-TrailsMap.pdf' target='_blank'>Jefferson County Trails Map</a>
            </p>
          </ListItem>

          <ListItem>

            <p><b>The Maharishi Vedic Observatory - <a href='https://maps.app.goo.gl/JRznYuJQLmXSthpf6' target='_blank'>map</a></b><br />A one-and-a-half-acre open-air observatory featuring masonry sundials
              . It's designed to display the entire structure of the universe, along with the movements of the sun, planets, and stars, all in one compact form. This ancient Vedic technology mirrors both the universe’s structure and our own physiology. </p>
          </ListItem>
          <ListItem><p><b>Fairfield Square - <a href='https://maps.app.goo.gl/KA5w9y7LW9Aruxpy5' target='_blank'>map</a></b><br />Explore the Fairfield downtown square, lots of shops and maybe the best coffee in Iowa at Cafe Paradiso. The rotating collections at the ICON gallery are great</p></ListItem>
          <ListItem><p><b>Parks</b><br />Enjoy the beauty of Jefferson County Park, perhaps attend an amazing wedding there!<br />
            Chautauqua Park, Lampson Woods State Preserve, and Whitham Woods are all beautiful in their own right, and they're all connected on the loop trail. (I feel like I should get a stipend from the Fairfield tourism board haha)
          </p></ListItem>
          <ListItem><p>Maasdam Barns - <a href='https://maps.app.goo.gl/9Fnpvy63PMJUxLBcA' target='_blank'>map</a><br />
            Enjoy a historical introduction to Fairfield at the Maasdam Barns. The former 20th century draft horses breeding business is now a recreational, historical and educational center.</p></ListItem>
          <ListItem>
            <p><b><a href='https://www.miu.edu/about-miu' target='_blank'>Maharishi University of Management</a></b> <br />
              The university is a unique place to visit and see the architecture and gardens. The campus is home to the Golden Domes <a href='https://maps.app.goo.gl/bm822TLE56xvNTwf9' target='_blank'>(map)</a> and is fun to walk around. The campus is open to the public but the domes are private.

            </p>
          </ListItem>


          <ListItem><p><b>More info</b><br />Check out the <a href='https://www.travelfairfield.com/' target='_blank'>Fairfield tourism website</a> for more ideas and information.</p></ListItem>
        </List>

      </Paper>

      <Accommodations />

    </Container>
  );
};

export default Fairfield;