import { Paper, Typography } from "@mui/material";

const Accommodations = () => {

  
  return ( <>
  <Paper sx={{ pb: 3, pt: 2, px: 4 }}>
            <Typography variant="h6" gutterBottom>
              Accommodations
            </Typography>
            <p><b>AmericInn by Wyndam Fairfield</b>
              <br /> This is the closest hotel to the venue and the best option if you're planning to stay in a hotel in town. <br />
              2104 South Main Street, Fairfield, IA 52556. <br />
              (866) 441-9308<br />
              <a href="https://www.wyndhamhotels.com/americinn/fairfield-iowa/americinn-hotel-and-suites-fairfield/overview?iata=00093763&cid=PS:27a9k1cnkqcs81d&gad_source=1&gclid=Cj0KCQjwsaqzBhDdARIsAK2gqneAuecBjT9ARKd1wlhqV8aQ2GkDqbvXhTq4YGzhKiYbdMme8ngLAfgaAjWmEALw_wcB&gclsrc=aw.ds" target="_blank" rel="noreferrer">Website</a>
            </p>
            <p><b><s>Super 8</s> - no vacancy</b><br />
              3001 W Burlington Ave, Fairfield, IA 52556 <br />
              +1 (641) 458-4189<br />
              <a href='https://www.wyndhamhotels.com/super-8/fairfield-iowa/super-8-fairfield/overview?CID=LC:yytq4fvvehxh7se:03004&iata=00093796' target='_blank' rel='noreferrer'>Website</a>
            </p>
            <p>
              <b>Jefferson County Park</b><br />
              Camping is available at the park and the campgrounds are just a 10 minute hike from the ceremony!<br />
              Campsites are first come first serve.
              2003 Libertyville Rd, Fairfield, IA 52556 <br />
              <a href="https://www.mycountyparks.com/County/Jefferson/Park/Jefferson-County-Park.aspx" target="_blank" rel="noreferrer">Website</a>
            </p>
            <p>
              <b>Reach out to us!</b><br />
              Give us a call and we can help you find a place to stay or answer any questions
            </p>


          </Paper>
  
  </> );
}
 
export default Accommodations;