import React from 'react';
import CloseIcon from '@mui/icons-material/Close';

const LiteBox = ({ image, show, setShow }) => {
  return (
    <>
      {
        show && (
          <div className='litebox-overlay' onClick={() => setShow(false)}>
            {/* <div className="litebox"> */}
              <div className="litebox-close" onClick={() => setShow(false)}><CloseIcon fontSize='large' /></div>
              <img src={image} alt="LiteBox Image" />
            {/* </div> */}
          </div>
        )
      }
    </>


  );
};

export default LiteBox;