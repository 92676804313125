import { Box, Button, Container, Grid, ImageList, ImageListItem, Paper } from "@mui/material";
import React from 'react';
import LiteBox from "../components/LiteBox";

const Gallery1 = () => {

  const [showLiteBox, setShowLiteBox] = React.useState(false);
  const [liteboxImage, setliteboxImage] = React.useState('');

  const setImage = (item) => {
    console.log(item);
    setliteboxImage(item.img);
    setShowLiteBox(true);
  }

  const itemData = [
    { img: "img/gallery_1/001.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/002.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/003.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/004.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/005.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/006.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/007.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/008.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/009.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/010.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/011.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/012.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/013.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/014.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/015.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/016.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/017.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/018.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/019.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/020.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/021.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/022.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/023.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/024.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/025.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/026.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/027.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/028.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/029.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/030.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/031.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/032.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/033.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/034.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/035.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/036.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/037.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/038.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/039.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/040.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/041.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/042.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/043.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/044.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/045.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/046.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/047.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/048.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/049.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/050.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/051.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/052.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/053.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/054.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/055.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/056.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/057.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/058.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/059.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/060.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/061.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/062.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/063.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/064.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/065.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/066.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/067.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/068.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/069.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/070.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/071.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/072.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/073.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/074.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/075.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/076.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/077.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/078.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/079.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/080.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/081.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/082.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/083.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/084.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/085.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/086.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/087.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/088.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/089.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/090.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/091.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/092.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/093.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/094.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/095.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/096.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/097.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/098.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/099.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/100.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/101.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/102.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/103.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/104.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/105.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/106.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/107.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/108.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/109.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/110.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/111.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/112.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/113.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/114.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/115.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/116.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/117.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/118.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/119.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/120.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/121.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/122.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/123.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/124.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/125.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/126.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/127.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/128.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/129.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/130.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/131.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/132.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/133.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/134.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/135.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/136.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/137.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/138.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/139.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/140.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/141.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/142.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/143.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/144.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/145.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/146.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/147.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/148.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/149.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/150.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/151.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/152.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/153.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/154.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/155.jpg", title: "murburus", rows: 3, cols: 2 },
    { img: "img/gallery_1/156.jpg", title: "murburus", rows: 3, cols: 2 },
  ]

  return (
    <Container>
      <div className='desktop-only'>
        <ImageList
          sx={{ width: '100%', height: 'auto', maxWidth: '1400px' }}
          variant="quilted"
          cols={4}
          rowHeight={121}
        >
          {itemData.map((item) => (
            <ImageListItem key={item.img} cols={item.cols || 1} rows={item.rows || 1} onClick={() => setImage(item)}>
              <img
                // {...srcset(item.img, 121, item.rows, item.cols)}
                // src={`${item.img}?w=121&h=121&fit=crop&auto=format`}
                src={`${item.img}`}
                alt={item.title}
                loading="lazy"
              />
            </ImageListItem>
          ))}
        </ImageList>
      </div>

      <div className='mobile-only'>
        <ImageList
          sx={{ width: '100%', height: 'auto', maxWidth: '1400px' }}
          variant="masonry"
          cols={1}
          gap={8}
        >
          {itemData.map((item) => (
            <ImageListItem key={item.img} cols={1} rows={1}>
              <img
                // {...srcset(item.img, 121, item.rows, item.cols)}
                // src={`${item.img}?w=121&h=121&fit=crop&auto=format`}
                src={`${item.img}`}
                alt={item.title}
                loading="lazy"
              />
            </ImageListItem>
          ))}
        </ImageList>
      </div>

      <LiteBox image={liteboxImage} show={showLiteBox} setShow={setShowLiteBox} />
    </Container>
  );
};

export default Gallery1;