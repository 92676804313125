import { Container, Typography, Grid, Paper, List, ListItem } from '@mui/material';
import React from 'react';
// import { Container, Typography, Grid, Paper } from '@material-ui/core';
import LiteBox from "../components/LiteBox";
import Accommodations from './Accommodations';

const Info = ({setOpenRsvp}) => {

  const [showLiteBox, setShowLiteBox] = React.useState(false);
  const [liteboxImage, setliteboxImage] = React.useState('');



  return (
    <Container className='content-wrap'>
      <Typography variant="h4" gutterBottom>
        Wedding Information
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Paper sx={{ pb: 3, pt: 2 }}>
            <Typography variant="h6" gutterBottom>
              Date and Time
            </Typography>
            Saturday, September 21st, 2024<br />
            3:00 PM - Ceremony<br />
            4:00 PM - Cocktails<br />
            5:00 PM - Dinner<br /><br />
            {/* <hr /> */}
            {/* <Typography variant="h6" gutterBottom>
              Attire
            </Typography> */}
            <p>
              Dress is informal. We'll be outside so please dress for comfort &amp; fun!
            </p>
          </Paper>

          <Paper sx={{ pb: 3, pt: 2, px: 4, mt: 3 }}>
            <Typography variant="h6" gutterBottom>
              Jefferson County Park
            </Typography>
            <p>2003 Libertyville Rd, Fairfield, IA 52556</p>
            <p>
              <b>Directions</b><br />
              From the entrance on Libertyville Rd, take the first left up the hill, then take the first right into the parking area
            </p>
            <Grid container spacing={3}>
              <Grid item xs={6} style={{ cursor: 'pointer' }}
                onClick={() => { setliteboxImage('img/map-fairfield.png'); setShowLiteBox(true) }}>
                <span>Fairfield Directions</span>
                <img src="img/map-fairfield.png" alt="Fairfield, IA" style={{ width: '100%' }} />
              </Grid>
              <Grid item xs={6} style={{ cursor: 'pointer' }}
                onClick={() => { setliteboxImage('img/map-park.png'); setShowLiteBox(true) }}>
                <span>Park Directions</span>
                <img src="img/map-park.png" alt="Jefferson County Park" style={{ width: '100%' }} />
              </Grid>
            </Grid>
          </Paper>

          {/* <Paper sx={{ pb: 3, pt: 2, mt: 4 }}>
            <p>
              Dress is informal. We'll be outside so please dress for comfort &amp; fun.
            </p>
          </Paper> */}

          


        </Grid>

        <Grid item xs={12} sm={6}>

        <Paper sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>
              RSVP
            </Typography>
            <p>
              You can <span onClick={() => setOpenRsvp(true)} className='text-link'><b>RSVP online</b></span> or reach out to us directly.
            </p>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <p>
                  <b>Daniel</b><br />
                  +1 (504) 259-6822
                </p>
              </Grid>
              <Grid item xs={6}>
                <p>
                  <b>Harriet</b><br />
                  +1 (504) 577-4830
                </p>
              </Grid>
            </Grid>
            <p>700 S 8th St Cir<br />Fairfield, IA 52556</p>

          </Paper>
          

          <Paper sx={{ p: 3, pb: 3, pt: 2, mt: 4 }}>
            <Typography variant="h6" gutterBottom>
              Gifts
            </Typography>
            <p>
              Your presence is our present, the best gift we could ask for! <br />
              <br />
              If you would like to give a gift, please consider donating to one of these charities:
            </p>
            <List>
              <ListItem>
                <a href="https://neworleansmusiciansclinic.org/get-involved/donate/" target="_blank">
                  New Orleans Musicians Clinic
                </a>
              </ListItem>
              
              <ListItem>
                <a href="https://donate.doctorswithoutborders.org/secure/donate?type=tribute" target="_blank">
                  Doctors Without Borders
                </a>
              </ListItem>

              <ListItem>
                <a href="https://upaconnect.org/" target="_blank">
                  United Palestinian Appeal
                </a>
              </ListItem>

            </List>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper sx={{ p: 3, pb: 3, pt: 2 }}>
            <Typography variant="h6" gutterBottom>
              Getting to Fairfield
            </Typography>
            <p>
              <b>By Car</b><br />
              
<i>From Des Moines</i><br />
2 hr drive to Fairfield. Take IA 5 S to IA 92 E to US 63 S to US 34 E. Exit 210 from 34.
</p>
<p>
<i>From Iowa City</i><br />
1 hr drive to Fairfield. Take US 218 S to US 34 W. Exit 212 from 34. 

            </p>
            <hr />
            <p>
              <b>By Plane</b><br />
              <i>Eastern Iowa Airport in Cedar Rapids</i><br />
1 hr 30 min drive to Fairfield. Take I-380 S to US 218 S to US 34 W. Exit 212 from 34. 
</p>

<p>
<i>Des Moines International Airport</i><br />
2 hr drive to Fairfield. Take IA 5 S to IA 92 E to US 63 S to US 34 E. Exit 210 from 34.
</p>

<p>
<a href='https://flybrl.com/flight-schedule/'><i>Southeast Iowa Regional Airport in Burlington Iowa</i></a>
  <br />
  This is a small, regional airport that has regularly scheduled flights to and from Chicago and St Louis. <br />
1 hr drive to Fairfield. Take 34 W. Exit 212 from 34.

</p>

<p>
  <i>O'Hare International Airport in Chicago</i><br />
4 hr 30 min drive to Fairfield. Various routes. 

</p>

<hr />

<p><b>By Train</b><br />
If you want to come to Fairfield by Amtrak, the closest stations are in Mt. Pleasant and Ottumwa, both about 30 min from Fairfield. </p>


          </Paper>
        </Grid>



        <Grid item xs={12}>
          <Accommodations />
        </Grid>

      </Grid>
      <LiteBox show={showLiteBox} setShow={setShowLiteBox} image={liteboxImage} />

    </Container>
  );
};

export default Info;