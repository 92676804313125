import React from "react";
import { Box, Button, Container, Grid, ImageList, ImageListItem, Paper } from "@mui/material";
import LiteBox from "../components/LiteBox";




const Home = ({ setOpenRsvp }) => {

    const [showLiteBox, setShowLiteBox] = React.useState(false);
    const [liteboxImage, setliteboxImage] = React.useState('');

    const itemData = [
        {
            img: 'img/008.jpg',
            title: 'murburus',
            rows: 3,
            cols: 3,
        },
        {
            img: 'img/003.jpg',
            title: 'murburus',
            rows: 3,
            cols: 1,
        },
        {
            img: 'img/004.jpg',
            title: 'murburus',
            rows: 3,
            cols: 1,
        },

        {
            img: 'img/005.jpg',
            title: 'murburus',
            rows: 3,
            cols: 3,
        },
        {
            img: 'img/006.jpg',
            title: 'murburus',
            rows: 4,
            cols: 1,
        },
        {
            img: 'img/001.jpg',
            title: 'murburus',
            rows: 4,
            cols: 2,
        },
        {
            img: 'img/011.jpg',
            title: 'murburus',
            rows: 4,
            cols: 1,
        },
        {
            img: 'img/002.jpg',
            title: 'murburus',
            rows: 4,
            cols: 2,
        },
        {
            img: 'img/012b.jpg',
            title: 'murburus',
            rows: 2,
            cols: 2,
        },




        {
            img: 'img/013.jpg',
            title: 'murburus',
            rows: 2,
            cols: 2,
        },

    ];

    const setImage = (item) => {
        console.log(item);
        setliteboxImage(item.img);
        setShowLiteBox(true);
    }

    return (<Container>
        <Paper className='architects-daughter-regular opening-message'>
            Dearest family and friends, <br />We’re so grateful to share this day with y’all.  Here we’ve collected all the <a href='/info'>dates and locations and details</a> about the wedding. <br />
            You can <span onClick={() => setOpenRsvp(true)} className='text-link'>click here to <b>RSVP</b> online</span>, but please feel free to reach out to us directly. <br />
            <span style={{ marginLeft: '36pt' }}>With love,</span>
            <Grid container spacing={2} sx={{ mt: 1, textAlign: 'center' }}>
                <Grid item sm={5}>Daniel<br />+1 (504) 259-6822</Grid>
                <Grid item sm={2}>&amp;</Grid>
                <Grid item sm={5}>Harriet<br />+1 (504) 577-4830</Grid>
            </Grid>
        </Paper>
        <div className='desktop-only'>
            <ImageList
                sx={{ width: '100%', height: 'auto', maxWidth: '1400px' }}
                variant="quilted"
                cols={4}
                rowHeight={121}
            >
                {itemData.map((item) => (
                    <ImageListItem key={item.img} cols={item.cols || 1} rows={item.rows || 1} onClick={() => setImage(item)}>
                        <img
                            // {...srcset(item.img, 121, item.rows, item.cols)}
                            // src={`${item.img}?w=121&h=121&fit=crop&auto=format`}
                            src={`${item.img}`}
                            alt={item.title}
                            loading="lazy"
                        />
                    </ImageListItem>
                ))}
            </ImageList>
        </div>

        <div className='mobile-only'>
            <ImageList
                sx={{ width: '100%', height: 'auto', maxWidth: '1400px' }}
                variant="masonry"
                cols={1}
                gap={8}
            >
                {itemData.map((item) => (
                    <ImageListItem key={item.img} cols={1} rows={1}>
                        <img
                            // {...srcset(item.img, 121, item.rows, item.cols)}
                            // src={`${item.img}?w=121&h=121&fit=crop&auto=format`}
                            src={`${item.img}`}
                            alt={item.title}
                            loading="lazy"
                        />
                    </ImageListItem>
                ))}
            </ImageList>
        </div>

        <LiteBox image={liteboxImage} show={showLiteBox} setShow={setShowLiteBox} />

    </Container>);
}

export default Home;