import { Button, Container, List, ListItem, Paper } from '@mui/material';
import React from 'react';

const Photos = () => {
  return (
    <Container>
      <Paper sx={{ p: 2, mt: 2, mb: 3 }}>

        <Button variant='outline' href='/gallery1'
          style={{ background: 'black', color: 'white', padding: '12px 24px', fontSize: '18pt', marginRight: '12px' }}>
          WEDDING PHOTOS</Button>


        <Button variant='outline' href='/gallery2'
          style={{ background: 'black', color: 'white', padding: '12px 24px', fontSize: '18pt' }}>
          PHOTOBOOTH PHOTOS</Button>

      </Paper>




    </Container>
  );
};

export default Photos;