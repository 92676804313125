import { AppBar, Container, Menu, MenuItem } from "@mui/material";

const Header = () => {
  return (<>
    <header id='main-header'>
      <Container className="header-text">
        <div className="our-names">
          <h2 className="text-right">Harriet<br />Burbeck</h2>
          <h4>&amp;</h4>
          <h2 className="text-left">Daniel<br />Murphy</h2>

        </div>
        <h2 className="date">September 21, 2024</h2>
        <h2 className="date">Jefferson County Park<br />Fairfield, Iowa</h2>
      </Container>

      <div className="header-bg1">
        <img src='../img/banner-left.png' alt='moon image' />
      </div>
      <div className="header-bg2">
        <img src='../img/banner-right.png' alt='murburus united' />
      </div>
      {/* <div id='main-header-title'>September 21, 2024</div> */}
    </header>

  </>);
}

export default Header;